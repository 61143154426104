import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GrtSecurityLayout from '@/modules/common/views/layouts/security/security.layout.vue'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Ref, Vue } from 'vue-property-decorator'
import _ from 'lodash'

@Component({
  name: 'GtrRegisterCompanyView'
})
export default class GrtRegisterCompanyView extends Vue {
  @Ref()
  readonly observerRegisterCompanyForm!: InstanceType<typeof ValidationObserver>

  data () {
    return {
      submitting: false,
      company: {
        name: null,
        logo: null
      }
    }
  }

  created () {
    this.$emit('update:layout', GrtSecurityLayout)
  }

  async submit () {
    const form = this.$refs.registerCompanyForm as HTMLFormElement
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload = _.cloneDeep(this.$data.company)
        const response = await this.$store.dispatch('company/registerCompany', payload)
        this.$data.company = {
          name: null,
          logo: null
        }
        this.$router.push({ name: 'level-two.company.dashboard', params: { uuid: response.uuid } })
      } catch (error) {
        if (error.data) {
          const errors = error.data.errors
          if (errors) {
            this.showServerErrors(errors)
          }
        }
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  async handleRegisterLater () {
    try {
      await this.$store.dispatch('security/logout')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$router.push({ name: 'security.login' })
    }
  }

  private showServerErrors (errors: Record<string, string>) {
    this.observerRegisterCompanyForm.setErrors(errors)
  }
}
